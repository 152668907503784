import React from 'react';

import { Layout, SEO } from 'components';
import { Authors } from 'containers'

const AuthorPage = () => (
  <Layout noBackground>
    <SEO title='Authors' />
    <Authors />
  </Layout>
);

export default AuthorPage;
